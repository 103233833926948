export const workList = [
    {
        id: 1,
        name: 'NUShare',
        url: 'nushare',
        img: '/Northeastern_Huskies_logo.svg.png',
        location: 'NORTHEASTERN UNIVERSITY • BOSTON, MA'
    },
    {
        id: 2,
        name: 'Voice prototype',
        url: 'voice',
        img: '/walmart_logo.jpg',
        location: 'WALMART • SUNNYVALE, CA'
    },
    {
        id: 3,
        name: 'Camera as a platform',
        url: 'caap',
        img: '/caap.png',
        location: 'WALMART • SUNNYVALE, CA'
    },
    // {
    //     id: 4,
    //     name: 'Dishaa Pratishthaan',
    //     url: 'dishaa',
    //     img: '/DishaaLogo.png',
    //     location: 'DISHAA PRATISHTHAAN • MUMBAI, INDIA'
    // }
];